<template>
  <v-row>
    <v-col cols="12" md="12">
      <div id="map" style="height: 300px; width: 100%"></div>
    </v-col>
  </v-row>
</template>
<script>
// import arcgisjs from './arcGisJs.js';
export default {
  name: "arcgis",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "ArcGis",
  },
  //   ,
  //   mounted: function () {
  //     // arcgisjs.pleasework(this); // give pleasework a reference to the Vue instance
  //   },
};
</script>
<script>
import { loadModules } from "esri-loader";
export default {
  mounted() {
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/CSVLayer",
        "esri/widgets/Legend",
      ],
      { css: true }
    ).then(([Map, MapView, CSVLayer, Legend]) => {
      // let map = new Map({
      //   basemap: "streets",
      // });
      // let view = new MapView({
      //   container: "map",
      //   map: map,
      //   zoom: 4,
      //   center: [15, 65],
      // });
      const url =
        "https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/2.5_week.csv";
      // Paste the url into a browser's address bar to download and view the attributes
      // in the CSV file. These attributes include:
      // * mag - magnitude
      // * type - earthquake or other event such as nuclear test
      // * place - location of the event
      // * time - the time of the event

      const template = {
        title: "{place}",
        content: "Magnitude {mag} {type} hit {place} on {time}.",
      };

      // The heatmap renderer assigns each pixel in the view with
      // an intensity value. The ratio of that intensity value
      // to the maxPixel intensity is used to assign a color
      // from the continuous color ramp in the colorStops property

      const renderer = {
        type: "heatmap",
        colorStops: [
          { color: "rgba(63, 40, 102, 0)", ratio: 0 },
          { color: "#472b77", ratio: 0.083 },
          { color: "#4e2d87", ratio: 0.166 },
          { color: "#563098", ratio: 0.249 },
          { color: "#5d32a8", ratio: 0.332 },
          { color: "#6735be", ratio: 0.415 },
          { color: "#7139d4", ratio: 0.498 },
          { color: "#7b3ce9", ratio: 0.581 },
          { color: "#853fff", ratio: 0.664 },
          { color: "#a46fbf", ratio: 0.747 },
          { color: "#c29f80", ratio: 0.83 },
          { color: "#e0cf40", ratio: 0.913 },
          { color: "#ffff00", ratio: 1 },
        ],
        maxPixelIntensity: 25,
        minPixelIntensity: 0,
      };

      const layer = new CSVLayer({
        url: url,
        title: "Magnitude 2.5+ earthquakes from the last week",
        copyright: "USGS Earthquakes",
        popupTemplate: template,
        renderer: renderer,
      });

      const map = new Map({
        basemap: "gray-vector",
        layers: [layer],
      });

      const view = new MapView({
        container: "map",
        center: [-138, 30],
        zoom: 2,
        map: map,
      });

      view.ui.add(
        new Legend({
          view: view,
        }),
        "bottom-left"
      );
    });
  },
};
</script>
